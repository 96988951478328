<template>
  <div class="grid crud-demo">
    <div class="col-12">
      <div class="card">
        <Toolbar class="mb-4">
          <template v-slot:end>
            <div class="my-2">
              <Button label="Nova Lista" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
            </div>
          </template>
        </Toolbar>

        <DataTable ref="dt"
                   :value="despesas"
                   dataKey="id"
                   :paginator="true"
                   :rows="10"
                   :lazy="true"
                   :loading="loading"
                   :total-records="totalRecords"
                   @page="findAll($event)"
                   :filters="filters"
                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                   :rowsPerPageOptions="[5,10,25]"
                   currentPageReportTemplate="Total {totalRecords} produtos"
                   responsiveLayout="scroll">
          <template #empty>
            Nenhum registro encontrado.
          </template>
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Compras</h5>
              <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Pesquisar..." />
                            </span>
            </div>
          </template>
          <Column field="name" header="Nome" :sortable="true">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Nome</span>
              <span v-show="!skeletor">{{slotProps.data.name}}</span>
            </template>
          </Column>
          <Column field="dtPurchase" header="Data da Compra">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Data da Compra</span>
              <span v-show="!skeletor">{{ slotProps.data.dtPurchase }}</span>
            </template>
          </Column>
          <Column field="inventoryType" header="Tipo">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Tipo</span>
              <span v-show="!skeletor" class="bg-blue-200 mr-2 mt-2 text-black-400 border-round py-1 px-2 text-sm"
              >{{slotProps.data.inventoryType}}</span>
            </template>
          </Column>
          <Column field="total" header="Total">
            <template #body="slotProps">
              <Skeleton v-show="skeletor"></Skeleton>
              <span class="p-column-title">Total</span>
              <span v-show="!skeletor">{{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(slotProps.data.total)}}</span>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button v-show="!skeletor" icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="edit(slotProps.data)" />
              <Button v-show="!skeletor" icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDelete(slotProps.data)" />
            </template>
          </Column>
        </DataTable>



        <Dialog v-model:visible="deleteDespesaDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
          <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span v-if="despesa">Alguns produtos podem ficar vazios no estoque. Você tem certeza que deseja excluir? <b>{{ despesa.name }}</b>?</span>
          </div>
          <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="deleteDespesaDialog = false"/>
            <Button label="Sim" icon="pi pi-check" class="p-button-text" @click="deletar(despesa.id)" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import {FilterMatchMode} from "primevue/api";
import moment from "moment";
import InventoryService from "@/service/InventoryService";

export default {
  name: "despesa-list",
  created() {
    this.inventaryService = new InventoryService();
    this.moment = moment;
    moment.locale('pt-BR');
    this.initFilters();
  },
  async mounted() {
    this.loading = true;
    await this.findAll(this.page);
    this.loading = false;
  },
  data() {
    return {
      despesa: {},
      despesas: [],
      inventaryService: null,
      filters: {},
      deleteDespesaDialog: false,
      despesaDialog: false,
      totalRecords: 0,
      loading: false,
      skeletor: false,
      page: {
        originalEvent: {
          rows: 10,
          page: 0
        }
      },
    }
  },
  methods: {

    async findAll(event) {
      this.skeletor = true;
      const response = await this.inventaryService.findAll(event.originalEvent.rows, event.originalEvent.page);
      if(response.status === 200) {
        this.despesas = response.data.content;
        this.totalRecords = response.data.totalElements;
      }
      this.skeletor = false;
    },

    initFilters() {
      this.filters = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    },

    formatCurrency(value) {
      if(value)
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'});
      return;
    },

    openNew() {
      this.$router.push('despesa-tab');
    },

    edit(despesa) {
      this.$router.push({name: 'despesa-tab', query: {id: despesa.id}});
    },

    confirmDelete(despesa) {
      this.despesa = despesa;
      this.deleteDespesaDialog = true;
    },

    async deletar(id) {
      this.loading = true;
      try {
        const response = await this.inventaryService.delete(id);
        if(response.status === 200) {
          this.$toast.add({
            severity: 'success',
            summary: 'Sucesso',
            detail: 'Compra excluída com sucesso.',
            life: 3000
          });
          this.deleteDespesaDialog = false;
        }
      } catch(e) {
        this.deleteDespesaDialog = false;
        this.$toast.add({
          severity: 'warn',
          summary: 'Alerta',
          detail: 'Você deve excluír os produto(s) antes de excluir a compra,',
          life: 4000
        });
      }
      await this.findAll(this.page);
      this.loading = false;
    },
  }
}
</script>

<style scoped>

</style>
